<template>
    <div>
        <div class="mobil-m-none m-4">
            <div class=" ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div
                                :class="
                                    mode
                                        ? 'content__titleday'
                                        : 'content__titlenight'
                                "
                                class="content-title d-flex align-center mr-2"
                            >
                                {{ $t('message.project') }}
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right content__setting">
                        <el-popover
                            placement="bottom"
                            trigger="click">
                            <div class="p-2">
                                <el-button v-for="(filial, index) in filials" :key="index" @click="copyProductCatalogLink(filial.id)" class="w-100 m-2 d-block" size="mini">
                                    {{ filial.name }}
                                </el-button>
                            </div>
                            <el-button slot="reference" size="mini">
                                {{ $t('message.product_catalog') }}
                            </el-button>
                        </el-popover>
                        
                        <el-button
                            v-if="permissions.some(per => per.slug == 'projects.create')"
                            size="mini"
                            @click="drawerCreate = true">
                                {{ $t('message.create') }}
                        </el-button>
                    </el-col>
                </el-row>
            </div>

            <div class=" table-crm-smart" v-loading="loadingData">
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6"
                        v-for="project in list" :key="'project-'+project.id">
                        <div class="p-my-card mt-3">
                            <div class="p-my-card-header">
                                <div class="p-my-card-header-left">
                                    <span>
                                        <router-link class="prayekt-name" :to="{ name: 'project', params: {project_id: project.id}}">
                                          {{project.name}}
                                        </router-link>
                                    </span>
												<i class="fa-regular fa-star fa-solid mr-3" :class="[project.mark ? 'mark_enable' : '']" @click="changeTypeMark(project.id)"></i>
                                    <crm-settings
                                        :name="$options.name"
                                        :model="project"
                                        :actions="actions"
                                        :permissionShow="'projects.update'"
                                        :permissionDestroy="'projects.delete'"
                                        @edit="edit"
                                        @delete="destroy"
                                    ></crm-settings>
                                </div>
                                <div
                                    v-popover.left="{name: 'project_color'+project.id}"
                                    class="proj_color"
												:class="['proj_color'+project.color]"
												style="cursor: pointer;"
                                ></div>
                                <popover :name="'project_color'+project.id" event="click" :width="200" style="cursor: pointer;">
                                    <div class="my-title-popover">
                                        {{ $t('message.project_color') }}
                                    </div>
                                    <div class="itme-color" v-for="number in 10" :key="number">
                                       <div class="i-color-block" :class="['proj_color'+number]" @click="changeProjectColor(number, project.id)"></div>
                                    </div>
                                </popover>
                            </div>
                            <div class="p-my-card-body" style="min-height: 241px">
                                <div class="p-my-card-body-top">
                                    <a class="ava thickbox"  v-popover.right="{name: 'pUser'+project.user.id}">
                                        <span class="av_sm av_let9 ru-0"> {{ project.user ? (project.user.surname ? project.user.surname.substring(0,1) : '') : '' }}
										{{ project.user ? (project.user.name ? project.user.name.substring(0,1) : '') : '' }} </span>
                                    </a>
                                    <popover :name="'pUser'+project.user.id" :width="250" event="hover" class="p-0 hidden">
                                        <div class=" myir">
                                             <div class="top-u-click">
                                                <span>
                                                    <img v-if="project.user.avatar"
                                                        :src="project.user.avatar"
                                                        alt="" class="task_user_give_img">
                                                    <img v-else src="/img/flag/employee.svg" alt="" class="task_user_give_img">
                                                </span>
                                                <div class="tt-name">
                                                   <div> {{ project.user ? project.user.name : '' }}</div>
                                                   <span><i class="el-icon-warning-outline"></i> ID: {{  project.user ? project.user.id : '' }}  </span>
                                                   <span><i class="el-icon-mobile-phone"></i> Tel: {{ project.user ? project.user.phone : '' }} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </popover>
                                    <div class="ava-title">
                                        <div>
                                            {{ $t('message.created') }} <b>{{ project.begin_date ? project.begin_date : '' }}</b>
                                            <!-- <span class="hover-b"
                                                >остался 1 месяц</span
                                            > -->
                                        </div>
                                        <div>
                                            {{ $t('message.finish') }} <b>{{ project.end_date ? project.end_date : '' }}</b>
                                            <!-- <span class="hover-b"
                                                >остался 1 месяц</span
                                            > -->
                                        </div>
                                        <div>
                                            <span class="hover-b" v-if="project.datediff">остался {{ project.datediff }} {{ $t('message.days') }} </span>
                                            <span class="hover-b" v-else>{{ $t('message.project without a deadline') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="my-percentags">
                                    <div class="itme-percentage mt-3">
                                        <div class="t-percentage">
                                            <div class="bar f-12">
                                                <span
                                                    class="ico ico_money"
                                                ></span>
                                                {{ $t('message.tasks') }} <b>{{project.tasks.percent}}%</b>
                                                <span class="hover-b">
                                                    {{ $t('message.completed') }}  {{ project.tasks.sum_ready_tasks }} из {{ project.tasks.sum_tasks }}
                                                    <a href="#" class="over_cnt">{{project.tasks.sum_overdue_tasks}} {{ $t('message.overdue') }}</a>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="home-percentage mt-2">
                                            <div
                                                :style="'width:'+project.tasks.sum_ready_tasks+'%'"
                                                class="left-percentage proj_color8"
                                            ></div>
                                            <div
                                                :style="'width:'+project.tasks.sum_overdue_tasks+'%'"
                                                class="right-percentage proj_color1"
                                            ></div>
                                        </div>
                                    </div>
                                    <div class="itme-percentage mt-3">
                                        <div class="t-percentage">
                                            <div class="bar f-12">
                                                <span class="ico ico_money" ></span>
                                                Время <b>0%</b>
                                                <span class="hover-b">
                                                    потрачено 00:00 из
                                                    <a href="#" class=" link-color">{{ project.plan_hour }} часов</a>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="home-percentage mt-2">
                                            <div
                                                style="width: 0%"
                                                class="left-percentage proj_color5"
                                            ></div>
                                        </div>
                                    </div>
                                    <div class="itme-percentage mt-3">
                                        <div class="t-percentage">
                                            <div class="bar f-12">
                                                <span
                                                    class="ico ico_money"
                                                ></span>
                                                Деньги <b>0%</b>
                                                <span class="hover-b">
                                                    потрачено 0 из
                                                    <a
                                                        href="#"
                                                        class=" link-color"
                                                        >{{ project.price | formatMoney(2)  }}</a
                                                    >
                                                </span>
                                            </div>
                                        </div>
                                        <div class="home-percentage mt-2">
                                            <div
                                                style="width: 0%"
                                                class="left-percentage proj_color9"
                                            ></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="avatars mt-4 d-flex align-items-center" v-if="project.project_users.length > 0">
                                    <div class="itme-avatars" v-for="item in project.project_users" :key="'project_user-'+item.id">
                                        <span class="av_sm av_let9"  v-popover.right="{name:'pUserbottom'+item.user.id}"  event="hover">
										    {{item.user.surname ? item.user.surname.charAt(0) : ''}} {{item.user.name ? item.user.name.charAt(0) : ''}}
										</span>
                                        <popover :name="'pUserbottom'+item.user.id" :width="250" event="hover" class="p-0 hidden">
                                            <div class="myir">
                                                <div class="top-u-click">
                                                    <span>
                                                        <img v-if="item.user.avatar"
                                                        :src="item.user.avatar" alt=""
                                                        class="task_user_give_img">
                                                    <img v-else src="/img/flag/employee.svg" alt="" class="task_user_give_img">
                                                    </span>
                                                    <div class="tt-name">
                                                        <div> {{ item.user.name }} </div>
                                                        <span><i class="el-icon-warning-outline"></i> ID: {{ item.user.id }} </span>
                                                        <span><i class="el-icon-mobile-phone"></i> Tel: {{ item.user.phone }} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </popover>
                                        <a class="cnt_over">1</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
			<div class="app-modal app-modal__full modal-color-bg">
                <el-drawer
                    class="uni-style-dialog"
                    :with-header="false"
                    :visible.sync="drawerCreate"
                    ref="drawerCreate"
                    @opened="drawerOpened('drawerCreateChild')"
                    @closed="drawerClosed('drawerCreateChild')"
                    @close="drawerClose('drawerCreateChild')"
                    size="90%">
                    <CrmCreate ref="drawerCreateChild" drawer="drawerCreate"/>
                </el-drawer>

                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerUpdate"
                    size="90%"
                    ref="drawerUpdate"
                    @opened="drawerOpened('drawerUpdateChild')"
                    @closed="drawerClosed('drawerUpdateChild')"
                >
                    <CrmUpdate ref="drawerUpdateChild" drawer="drawerUpdate" :selectedItem="selectedItem"/>
                </el-drawer>
        	</div>
    	</div>
</template>

<script>
import list from "@/utils/mixins/list";
import { mapGetters, mapActions} from "vuex";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import {i18n} from '@/utils/i18n';
export default {
    name: "projects",
    mixins: [list],
    components: {
        CrmCreate,
        CrmUpdate,
    },

    data() {
        return {};
    },
    watch: {
        filials: {
            handler: async function(newVal, oldVal) {
                if(this.filials.length == 0){
                    this.updateFilialInventory()
                }
            },
            deep: true,
            immediate: true
        },
    },

    computed: {
		...mapGetters({
			list: "projects/list",
			columns: "projects/columns",
			pagination: "projects/pagination",
			filter: "projects/filter",
			filials: "filials/userFilials",
			sort: "projects/sort",
			mode: "MODE",
			permissions: "auth/permissions"
		}),
		actions: function () {
			return ["edit", "delete"];
		},
    },
    methods: {
        ...mapActions({
			updateList: "projects/index",
            setPagination: "projects/setPagination",
            updateSort: "projects/updateSort",
            updateFilter: "projects/updateFilter",
            updateColumn: "projects/updateColumn",
            updatePagination: "projects/updatePagination",
            show: "projects/show",
            empty: "projects/empty",
            delete: "projects/destroy",
            refreshData: "projects/refreshData",
            updateStatusMark: "projects/updateStatusMark",
            updateProjectColor: "projects/updateProjectColor",
            updateFilialInventory: "filials/userFilials",
		  }),
          async copyProductCatalogLink(filial_id){
            try {
				let url = window.location.origin + `/product_client_show/${filial_id}`;
				await navigator.clipboard.writeText(url);

				this.$message({
					message: this.$t('message.linkCopied'),
					type: 'success'
				});
			} catch($e) {
				console.error('Link Cannot copy', $e);
			}
          },
		  changeTypeMark(project_id){
			if (project_id){
				this.updateStatusMark(project_id)
               .then((res) => {
                  this.$alert(res);
                  this.fetchData();
					});
			}
		  },
		  changeProjectColor(number, project_id){
			if(project_id && number){
				let data = {project_id: project_id, number_color: number};
				this.updateProjectColor(data)
					.then((res) => {
						if (res.status == 200){
							this.$notify({
								title: i18n.t('message.succesfully'),
								message: res.data.result.message,
								position: 'bottom-right',
								type: 'success'
							});
							this.fetchData();
						}
					});
			}
		  },
		  drawerClose(ref){
			if (this.$refs[ref]){
				if (_.isFunction(this.$refs[ref].beforeLeave)) {
					this.$refs[ref].beforeLeave();
				}
			}
		  }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("projects/EMPTY_LIST");
        next();
    },
};
</script>

<style>
.d-modal-style .p-my-card{
    border: 1px solid #181a32;
    background-color: #080c24;
}
.d-modal-style .p-my-card a, .d-modal-style .p-my-card span{
    color: #fff;
}
/* .d-modal-style  .proj_color{
    border-color: #ff8b00 #ff8b00 #080c24 #080c24;
} */
.p-my-card {
    border: 1px solid #ebeef5;
    background-color: #fff;
    color: #303133;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    position: relative;
}
.p-my-card-header {
    /* padding: 10px 20px; */
    border-bottom: 1px solid #ebeef5;
    box-sizing: border-box;
    border-top: 3px solid #0d67c9;
    display: flex;
    justify-content: space-between;
}
.d-modal-style .p-my-card-header{
    border-bottom: 1px solid #0a1c35;
}
.p-my-card-body {
    padding: 10px 20px 20px 20px;
}
.ava {
    display: flex;
    width: 30px;
    height: 30px;
    border: 1px solid #00253d2e;
    padding: 0px;
    margin: -4px 10px 0 0;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
}
span.av_sm {
    display: flex;
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 24px;
    text-align: center;
    color: #ffffff;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 10px;
    justify-content: center;
    align-items: center;
}
span.av_let9 {
    background-color: #f39dc3;
}
.ru-0 {
    border-radius: 0% !important;
}
.ava-title {
    font-size: 10px;
}
.p-my-card-body-top {
    display: flex;
    align-items: center;
}
.hover-b {
    opacity: 0;
}
.p-my-card:hover .hover-b {
    opacity: 1;
}
.ava-title > div {
    margin-bottom: 3px;
}
.home-percentage {
    width: 100%;
    background-color: #ebebeb;
    border-radius: 50px;
    height: 10px;
    overflow: hidden;
    position: relative;
}
.home-percentage > div {
    height: 10px;
    position: absolute;
}
.right-percentage {
    right: 0;
}
.left-percentage {
    left: 0;
}
.f-12 {
    font-size: 12px;
    color: #0c223173;
}
.f-12 b {
    color: #000;
}

.proj_color1 {
    background: #d03532 !important;
}
.proj_color2 {
    background: #a36c4e !important;
}
.proj_color3 {
    background: #ff8b00 !important;
}
.proj_color4 {
    background: #fdcc08 !important;
}
.proj_color5 {
    background: #26c23c !important;
}
.proj_color6 {
    background: #6a50b3 !important;
}
.proj_color7 {
    background: #4b75f4 !important;
}
.proj_color8 {
    background: #45b6e6 !important;
}
.proj_color9 {
    background: #bd68b0 !important;
}
.proj_color10 {
    background: #cbcfdf !important;
}
.itme-color .i-color-block {
    float: left;
    width: 26px;
    height: 26px;
    border-radius: 16px;
    margin: 2px 5px;
}
.my-title-popover {
    font-size: 14px;
    color: #041625a6;
    padding: 0px 10px;
    font-weight: 700;
    margin-bottom: 5px;
}

.proj_color {
    /* float: right; */
    margin: -0.5px 0 0 0;
    width: 0;
    height: 0;
    border: 12px solid #ffffff;
    border-color: transparent transparent #ffffff #ffffff;
    background-color: rgb(205, 205, 205);
}
.mark_enable{
	color: #ff8b00 !important;
}
</style>
